import Vue from 'vue';
import { BootstrapVue, IconsPlugin, SkeletonPlugin, ModalPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(SkeletonPlugin);
Vue.use(ModalPlugin);

//Date format
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

//Import pagination
Vue.component('pagination', require('laravel-vue-pagination'));

//Import v-from
import Form from 'vform'
window.Form = Form;
import {
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/bootstrap4'
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component(AlertErrors.name, AlertErrors);
Vue.component(AlertSuccess.name, AlertSuccess);

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
Vue.use( CKEditor );

export const BaseVue = {
    data() {
        let initialState = window.__INITIAL_STATE__;
        return {
            config: { headers: {'content-type': 'multipart/form-data'} },
            baseUrl: initialState.baseUrl || "",
            authId: initialState.authId || null,
            profileId: initialState.profileId || null,
            isMasterAdmin: initialState.isMasterAdmin || null,
            adTypes: initialState.adTypes || null,
            categoryOptions: initialState.categories || null,
            locationOptions: initialState.locations || null,
            qualificationOptions: initialState.qualifications || null,
            typeOptions: initialState.types || null,
            profileOptions: initialState.profiles || null,
            activeCategory: null,
            activeLocation: null,
            activeCompany: null,
            loading: false,
            loadingTime: 0,
            maxLoadingTime: 1,
            currentPage: 1,
            totalRows: 0,
            perPage: 12,
            isBusy: false,
            filter: null,
            toastCount: 0,
            deleteData: {
                id: null,
                title: '',
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        'bold',
                        'italic',
                        'link',
                    ]
                }
            },
            rowId: null,
            apiStatus: null,
        }
    },
    created() {
        this.$_loadingTimeInterval = null;
    },
    mounted() {
        this.startLoading();
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clearLoadingTimeInterval();
                if (newValue) {
                    this.$_loadingTimeInterval = setInterval(() => {
                        this.loadingTime++;
                    }, 1000)
                }
            }
        },
        loadingTime(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === this.maxLoadingTime) {
                    this.loading = false;
                }
            }
        }
    },
    filters: {
        stringLimit(value, size) {
            if (!value) return '';
            value = value.toString();

            if (value.length <= size) {
                return value;
            }

            return value.substr(0, size) + '...';
        }
    },
    methods: {
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval);
            this.$_loadingTimeInterval = null;
        },
        startLoading() {
            this.loading = true;
            this.loadingTime = 0;
        },
        toastAlert(title, message, variant, append= false) {
            this.toastCount++
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                appendToast: append,
                autoHideDelay: 10000
            })
        },
        getPDFFileType(file) {
            return (file.split(".").pop() === 'pdf');
        },
        copyText() {
            let testingCodeToCopy = document.querySelector('#copy-code');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            document.execCommand('copy');
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        showDeleteModal(id, title) {
            this.rowId = id;
            this.apiStatus = true;
            this.deleteData = { id: id, title: title };
            this.$refs['deleteModal'].show();
        },
        hideDeleteModal() {
            this.deleteData = {}
            this.$refs['deleteModal'].hide();
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 0) return 'table-warning'
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        currentRowCount(total) {
            if (total === undefined) {
                return '0 - 0 of 0';
            }
            if (!this.filter) {
                if (total <= this.perPage) {
                    return '0 - ' + total + ' of ' + total;
                }
                return '0 - ' + this.perPage + ' of ' + total;
            }
            return '0 - ' + this.totalRows + ' of ' + total;
        },
        getActionStatus(id) {
            if (this.rowId === id) {
                if (this.apiStatus) {
                    return 'check2-circle'
                }
                return 'exclamation-triangle';
            }
            return '';
        },
    },
};
