import { BaseVue } from './base';
import Vue from "vue";

new Vue({
    el: '#app',
    mixins: [BaseVue],
    data() {
        let pageInfo = window.__PAGE_STATE__;
        return {
            postId: pageInfo.postId || null,
            categoryId: pageInfo.categoryId || null,
            isHome: pageInfo.isHome || null,
            activeCategory: pageInfo.activeCategory || null,
            activeLocation: pageInfo.activeLocation || null,
            activeCompany: pageInfo.activeCompany || null,
            activeSearch: null,
            tableFields: [
                { key: 'id', label: 'ID', sortable: true },
                { key: 'file', label: '' },
                { key: 'category', label: 'Category', sortable: true },
                { key: 'location', label: 'Location', sortable: true },
                { key: 'type', label: 'Type', sortable: true },
                { key: 'qualification', label: 'Qualification', sortable: true },
                { key: 'experience', label: 'Experience', sortable: true },
                { key: 'salary', label: 'Salary', sortable: true, class: 'text-right text-nowrap' },
                { key: 'closing_date', label: 'Deadline', sortable: true, class: 'text-nowrap' },
                { key: 'status', label: '', class: 'text-right text-nowrap' }
            ],
            filterOn: ['title', 'body', 'location', 'category', 'type', 'qualification', 'experience'],
            activePost: {},
            posts: {},
            paginationPage: 1,
            newPost: new Form({
                user_id: null,
                type_id: null,
                qualification_id: null,
                experience: null,
                closing_date: null,
                salary: null,
                category_id: null,
                location_id: null,
                title: '',
                body: '',
                file: null
            }),
            newApply: new Form({
                first_name: '',
                last_name: '',
                address: '',
                location_id: null,
                home_phone: '',
                home_email: '',
                experience: null,
                qualification_id: null,
                file: null
            }),
            editPostFile: null,
            likeCount: 0,
            applyCount: 0,
        }
    },
    created() {
        if (this.postId) {
            this.getPostById(this.postId);
            this.getPostApplyCountById();
            this.getPostLikeCountById();
        } else if (this.activeCompany) {
            this.getPostsByCompanyId(this.activeCompany.id)
        } else if (this.activeCategory) {
            this.getPostsByCategoryId(this.activeCategory.id)
        } else if (this.activeLocation) {
            this.getPostsByLocationId(this.activeLocation.id)
        } else {
            this.getPosts();
        }

        let activeUrl = new URL(window.location.href);
        let searchKey = activeUrl.searchParams.get('search');
        if (searchKey) {
            this.activeSearch = searchKey;
            this.getPostsBySearchKey();
        }
    },
    methods: {
        getPosts(page) {
            let pageVal = page;
            if (typeof page === 'undefined') {
                pageVal = 1;
            }
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPosts/${existingObj.isMasterAdmin ? 'null' : existingObj.authId}/${existingObj.isHome}?page=${pageVal}`)
                .then(function (response) {
                    existingObj.activeSearch = null;
                    existingObj.activeCategory = null;
                    existingObj.activeLocation = null;
                    existingObj.activeCompany = null;
                    existingObj.posts = response.data;
                    existingObj.paginationPage = response.data.current_page;
                }).catch(error => {
                existingObj.toastAlert(
                    'Post collections',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPostsBySearchKey() {
            const formSearchField = document.getElementById("formSearchField");

            let existingObj = this;
            existingObj.activeSearch = formSearchField.value ? formSearchField.value : this.activeSearch;

            if (existingObj.activeSearch === '') {
                existingObj.toastAlert(
                    'Search collections',
                    'Please enter search keyword',
                    'danger',
                    true
                );
            } else {
                if (existingObj.postId) {
                    window.location.href = existingObj.baseUrl + '?search=' + formSearchField.value;
                }
                axios.get(`${this.baseUrl}/api/getPostsBySearchKey/${existingObj.activeSearch}`)
                    .then(function (response) {
                        existingObj.activeLocation = null;
                        existingObj.activeCompany = null;
                        existingObj.activeCategory = null;
                        existingObj.posts = response.data;
                        existingObj.paginationPage = response.data.current_page;
                    }).catch(error => {
                    existingObj.toastAlert(
                        'Search collections',
                        error.message,
                        'danger',
                        true
                    );
                });
            }
        },
        getPostsByCategoryId(id, page) {
            let pageVal = page;
            if (typeof page === 'undefined') {
                pageVal = 1;
            }
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostsByCategoryId/${id}/${existingObj.isHome}?page=${pageVal}`)
                .then(function (response) {
                    existingObj.activeSearch = null;
                    existingObj.activeLocation = null;
                    existingObj.activeCompany = null;
                    existingObj.posts = response.data;
                    existingObj.paginationPage = response.data.current_page;
                }).catch(error => {
                existingObj.toastAlert(
                    'Category collections',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPostsByLocationId(id, page) {
            let pageVal = page;
            if (typeof page === 'undefined') {
                pageVal = 1;
            }
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostsByLocationId/${id}/${existingObj.isHome}?page=${pageVal}`)
                .then(function (response) {
                    existingObj.activeSearch = null;
                    existingObj.activeCategory = null;
                    existingObj.activeCompany = null;
                    existingObj.posts = response.data;
                    existingObj.paginationPage = response.data.current_page;
                }).catch(error => {
                existingObj.toastAlert(
                    'Location collections',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPostsByCompanyId(id, page) {
            let pageVal = page;
            if (typeof page === 'undefined') {
                pageVal = 1;
            }
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostsByCompanyId/${id}/${existingObj.authId}?page=${pageVal}`)
                .then(function (response) {
                    existingObj.activeSearch = null;
                    existingObj.activeCategory = null;
                    existingObj.activeLocation = null;
                    existingObj.posts = response.data;
                    existingObj.paginationPage = response.data.current_page;
                }).catch(error => {
                existingObj.toastAlert(
                    'Company collections',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        selectAddFile(event) {
            if (this.rowId === null) {
                this.newPost.file = event.target.files[0];
            } else {
                let formData = new FormData();
                formData.append('file', event.target.files[0]);
                let existingObj = this;
                axios.post(`${this.baseUrl}/api/postUpdateFileById/${this.rowId}`, formData, this.config)
                    .then(function (response) {
                        if (response.status && response.status === 200) {
                            existingObj.apiStatus = true;
                            existingObj.editPostFile = response.data;
                            existingObj.toastAlert(
                                'Image upload',
                                'The image has been uploaded',
                                'success',
                                true
                            );
                        }
                    }).catch(error => {
                    existingObj.toastAlert(
                        'Image upload',
                        error.message,
                        'danger',
                        true
                    );
                });
            }
        },
        postCreatePost() {
            if (!this.isMasterAdmin) { this.newPost.user_id = this.authId; }
            let existingObj = this;
            this.newPost.post(`${this.baseUrl}/api/postCreatePost`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.apiStatus = true;
                        existingObj.toastAlert(
                            'Post create',
                            'The job post has been created',
                            'success',
                            true
                        );
                        existingObj.hideAddModal();
                        existingObj.newPost.reset();
                        existingObj.getBackToSamePlace();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Post create',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        postUpdatePostById() {
            let idVal = this.rowId;
            let existingObj = this;
            this.newPost.put(`${this.baseUrl}/api/postUpdatePostById/${idVal}`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.apiStatus = true;
                        existingObj.toastAlert(
                            'Post update',
                            'The post ' + idVal + ' has been update',
                            'success',
                            true
                        );
                        existingObj.hideEditModal();
                        existingObj.newPost.reset();
                        existingObj.getBackToSamePlace();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Post update',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPublishById(id) {
            this.rowId = id;
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostPublishById/${id}`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.apiStatus = true;
                        existingObj.getBackToSamePlace();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Post publish',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        deleteDataById() {
            let existingObj = this;
            axios.delete(`${this.baseUrl}/api/deletePostById/${existingObj.deleteData.id}`)
                .then(function (response) {
                    if (response.status && response.status === 204) {
                        existingObj.toastAlert(
                            'Post delete',
                            `${response.data} has been deleted`,
                            'success',
                            true
                        );
                        existingObj.$refs['deleteModal'].hide();
                        existingObj.getBackToSamePlace();
                    }
                    if (response.status && response.status === 206) {
                        existingObj.toastAlert(
                            'Post delete',
                            `${response.data} has not been deleted`,
                            'danger',
                            true
                        );
                        existingObj.$refs['deleteModal'].hide();
                        existingObj.getBackToSamePlace();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Post delete',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        deletePhotoById(photoId) {
            let existingObj = this;
            axios.delete(`${this.baseUrl}/api/deletePhotoById/${photoId}`)
                .then(function (response) {
                    if (response.status && response.status === 204) {
                        existingObj.editPostFile = null;
                        existingObj.getBackToSamePlace();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Post delete',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getBackToSamePlace() {
            if (this.postId) {
                this.getPostById(this.postId);
            } else if (this.activeCompany) {
                this.getPostsByCompanyId(this.activeCompany.id, this.paginationPage);
            } else if (this.activeCategory) {
                this.getPostsByCategoryId(this.activeCategory.id, this.paginationPage);
            } else if (this.activeLocation) {
                this.getPostsByLocationId(this.activeLocation.id, this.paginationPage);
            } else {
                this.getPosts(this.paginationPage);
            }
        },
        showAddModal() {
            this.newPost.reset();
            this.rowId = null;
            this.editPostFile = null;
            this.$refs['addModal'].show();
        },
        hideAddModal() {
            this.$refs['addModal'].hide();
        },
        showEditModal(id) {
            this.activePost = this.postId ? this.activePost : this.posts.data.find((el) => el.id === id);
            this.newPost.fill({
                type_id: this.activePost.type_id,
                qualification_id: this.activePost.qualification_id,
                experience: this.activePost.experience,
                closing_date: this.activePost.closing_date,
                salary: this.activePost.salary,
                category_id: this.activePost.category_id,
                location_id: this.activePost.location_id,
                title: this.activePost.title,
                body: this.activePost.body,
                user_id: this.activePost.user_id,
            })
            this.rowId = id;
            this.editPostFile = this.activePost.file;
            this.$refs['editModal'].show();
        },
        hideEditModal() {
            this.editPostFile = null;
            this.$refs['editModal'].hide();
        },
        showApplyModal() {
            this.newPost.reset();
            this.$refs['applyModal'].show();
        },
        hideApplyModal() {
            this.$refs['applyModal'].hide();
        },
        getPostById(id) {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostById/${id}`)
                .then(function (response) {
                    existingObj.activePost = response.data;
                }).catch(error => {
                existingObj.toastAlert(
                    'Post view',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        selectAddApplyFile(event) {
            this.newApply.file = event.target.files[0];
        },
        postCreateApply() {
            let existingObj = this;
            this.newApply.post_id = this.postId;
            this.newApply.category_id = this.categoryId;
            this.newApply.post(`${this.baseUrl}/api/postCreateApply`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.applyCount = response.data;
                        existingObj.toastAlert(
                            'Apply',
                            'The application has been submitted',
                            'success',
                            true
                        );
                        existingObj.newApply.reset();
                        existingObj.hideApplyModal();
                    } else {
                        existingObj.toastAlert(
                            'Apply',
                            response.data,
                            'danger',
                            true
                        );
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Apply',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPostApplyCountById() {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostApplyCountById/${this.postId}`)
                .then(function (response) {
                    existingObj.applyCount = response.data;
                }).catch(error => {
                existingObj.toastAlert(
                    'Apply count',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getPostLikeCountById() {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getPostLikeCountById/${this.postId}`)
                .then(function (response) {
                    existingObj.likeCount = response.data;
                }).catch(error => {
                existingObj.toastAlert(
                    'Post like',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getUpdateLikeCountById() {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getUpdateLikeCountById/${this.postId}`)
                .then(function (response) {
                    existingObj.likeCount = response.data;
                }).catch(error => {
                existingObj.toastAlert(
                    'Post like',
                    error.message,
                    'danger',
                    true
                );
            });
        },
    },
});
